<script setup>
import {ref, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment/moment";

const router = useRouter();
const route = useRoute();

let selected_date_option = ref('all_time');
let show_custom_date_select_modal = ref(false);
let custom_date_config = ref({
  enableTime: false,
  altInput: true,
  dateFormat: "Z",
  altFormat: "d M, Y",
  mode: "range",
  date: '',
  inline: true
});
let date_options = ref([
  {label: 'All time', value: 'all_time'},
  {label: 'Last 7 days', value: 'last_7_days'},
  {label: 'Last 14 days', value: 'last_14_days'},
  {label: 'Last 30 days', value: 'last_30_days'},
  {
    label: 'Custom range...',
    value: 'custom_range',
    onClick: () => show_custom_date_select_modal.value = true
  },
]);

const handleDateOptionChange = async () => {
  if (selected_date_option.value === 'custom_range') {
    show_custom_date_select_modal.value = true;
  } else {
    const {start_date, end_date} = calculateDateRange(selected_date_option.value);
    await updateRouterQuery(start_date, end_date);
  }
};

const calculateDateRange = (option) => {
  let end_date = moment().format('YYYY-MM-DD');
  let start_date;

  switch (option) {
    case 'last_7_days':
      start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
      break;
    case 'last_14_days':
      start_date = moment().subtract(14, 'days').format('YYYY-MM-DD');
      break;
    case 'last_30_days':
      start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
      break;
    case 'all_time':
      start_date = null
          break
    case 'custom_range':
      if (custom_date_config.value.date.length > 0) {
        start_date = moment(custom_date_config.value.date.split(' to ')[0]).format('YYYY-MM-DD');
        end_date = moment(custom_date_config.value.date.split(' to ')[1]).format('YYYY-MM-DD');
      } else {
        start_date = moment(route.query.start_date).format('YYYY-MM-DD');
        end_date = moment(route.query.end_date).format('YYYY-MM-DD');
      }
      break;
    default:
      start_date = null;
      break;
  }

  return {start_date, end_date};
};

const updateRouterQuery = async (start_date, end_date) => {
  const query = {...route.query};

  if (start_date) {
    query.start_date = start_date;
    query.end_date = end_date;
  } else {
    delete query.start_date;
    delete query.end_date;
  }

  await router.push({query});
};

const filterByCustomDateRange = async () => {
  if (custom_date_config.value.date) {
    if (custom_date_config.value.date.split(' to ').length === 2) {
      show_custom_date_select_modal.value = false;
      let start_date = moment(custom_date_config.value.date.split(' to ')[0]).format('YYYY-MM-DD');
      let end_date = moment(custom_date_config.value.date.split(' to ')[1]).format('YYYY-MM-DD');
      date_options.value.find(i => i.value === 'custom_range').label = formatDate(start_date) + ' - ' + formatDate(end_date);

      await updateRouterQuery(start_date, end_date);
    }
  }
};

const formatDate = (date) => {
  if (!date) return '';
  return moment(date).format('MMM DD, YYYY');
};

const getDaysDifference = () => {
  if (!custom_date_config.value.date) return 0;
  const [startDate, endDate] = custom_date_config.value.date.split(' to ');
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days') + 1; // Add 1 to include both start and end dates
};

// Initialize the date range based on the URL parameters
onMounted(() => {
  const {start_date, end_date} = route.query;
  if (start_date && end_date) {
    const diff = moment(end_date).diff(moment(start_date), 'days');
    if (moment(end_date).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
      if (diff === 7) {
        selected_date_option.value = 'last_7_days';
      } else if (diff === 14) {
        selected_date_option.value = 'last_14_days';
      } else if (diff === 30 || diff === 31) {
        selected_date_option.value = 'last_30_days';
      } else {
        selected_date_option.value = 'custom_range';
        date_options.value.find(i => i.value === 'custom_range').label = `${formatDate(start_date)} - ${formatDate(end_date)}`;
      }
    } else {
      selected_date_option.value = 'custom_range';
      date_options.value.find(i => i.value === 'custom_range').label = `${formatDate(start_date)} - ${formatDate(end_date)}`;
    }
  }
});

</script>

<template>

  <b-modal v-model="show_custom_date_select_modal" title="Select Custom Range" hide-footer centered>
    <flat-pickr placeholder="Select date"
                :config="custom_date_config"
                v-model="custom_date_config.date"
                class="form-control flatpickr-input w-100"
    >
    </flat-pickr>
    <div class="mt-3" v-if="custom_date_config.date">
      {{ formatDate(custom_date_config.date.split(' to ')[0]) }} -
      {{ formatDate(custom_date_config.date.split(' to ')[1]) }}
      <span v-if="custom_date_config.date.split(' to ').length === 2" class="text-muted">
        ({{ getDaysDifference() }} days)
      </span>
    </div>
    <div class="mt-3 text-center">
      <b-button v-if="custom_date_config.date"
                @click="filterByCustomDateRange()"
                :disabled="custom_date_config.date.split(' to ').length !== 2" variant="primary">Proceed
      </b-button>
    </div>
  </b-modal>


  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-6 col-lg-4">
      <div class="bg-white border overflow-hidden" style="border-radius: 8px">
        <select v-model="selected_date_option" @change="handleDateOptionChange"
                class="form-control form-control-lg border-0 pb-2">
          <template v-for="date in date_options" :key="`date_option_${date.value}`">
            <option :value="date.value">{{
                date.label
              }}
            </option>
          </template>
        </select>
        <div v-if="selected_date_option !=='all_time' " class="px-3">
          <label  class="">
            {{ calculateDateRange(selected_date_option).start_date }} -
            {{ calculateDateRange(selected_date_option).end_date }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[data-layout-mode="dark"] {
  .bg-white {
    background-color: #2a2f34 !important;
  }
}
</style>