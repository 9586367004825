<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";

import {useRoute, onBeforeRouteUpdate} from "vue-router";

const route = useRoute();

onBeforeRouteUpdate((to, from, next) => {
  if (to.query.start_date !== from.query.start_date || to.query.end_date !== from.query.end_date) {
    setTimeout(async () => {
      await getOrders(to.query);
    }, 500)
  }
  next();
});

let orders = ref([])
let is_loading = ref(true)

const getOrders = async () => {
  is_loading.value = true
  try {
    const {start_date, end_date} = route.query;
    let response = await axios.get('/order/sales_statistic', {
      params: (start_date && end_date) ? {
        date_from: start_date,
        date_to: end_date,
      } : {}
    })
    orders.value = response.data || []
    is_loading.value = false
  } catch {
    is_loading.value = false
    alert("Error while loading Order by Managers")
  }
}

onMounted(async () => {
  await getOrders()
})
</script>

<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center">
      <b-card-title class="mb-0">
        <b-spinner v-if="is_loading" class="fs-10 me-2 text-secondary" style="width: 15px; height: 15px"></b-spinner>
        Orders by Managers
      </b-card-title>
      <img src="@/assets/images/order-diamond-icon.webp" alt="Order Icon"
           style="height: 20px; width: 20px; margin-right: 5px">
    </b-card-header>
    <b-card-body class="mb-0 pb-0 pt-3">
      <div v-if="!is_loading" class="table-responsive">
        <table class="table table-sm table-nowrap">
          <thead>
          <tr class="text-center">
            <th class="text-start" scope="col">Manager</th>
            <th scope="col">Total Orders</th>
            <th scope="col">Agreed Rate</th>
            <th scope="col">Profit</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in orders" :key="`order_${item.manager}`">
            <tr class="text-center">
              <th class="text-start">
                <a class="fw-semibold" :class="{
                  'text-danger': !item.manager
                }">
                  {{ (item.full_name || '').trim().length > 0 ? item.full_name : item.manager || 'Unknown Manager' }}
                </a>
              </th>
              <td>{{ item.order_count || 0 }}</td>
              <td>$ {{
                  parseFloat(item.total_agreed_rate || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2, maximumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-success fw-medium">$ {{
                  parseFloat(item.total_profit || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2, maximumFractionDigits: 2
                  })
                }}
              </td>
            </tr>
          </template>
          <tr v-if="orders.length === 0">
            <td colspan="7" class="py-3 text-danger">
              No orders for given period
            </td>
          </tr>
          <tr v-else class="bg-light align-middle">
            <th>
              TOTAL
            </th>
            <th class="text-center">
              {{ orders.map(i => parseInt(i.order_count || 0)).reduce((a, b) => a + b, 0) }}
            </th>
            <th class="text-center">
              $ {{
                orders.map(i => parseFloat(i.total_agreed_rate || 0)).reduce((a, b) => a + b, 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2, maximumFractionDigits: 2
                })
              }}
            </th>
            <th class="text-center text-success">
              $ {{
                orders.map(i => parseFloat(i.total_profit || 0)).reduce((a, b) => a + b, 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2, maximumFractionDigits: 2
                })
              }}
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>