<script setup>
import {ref, onMounted, watch} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";

let statistics = ref([])
let is_loading = ref(false)
const route = useRoute();


const getStatistics = async () => {
  is_loading.value = true
  try {
    const {start_date, end_date} = route.query;
    let response = await axios.get('/order/sales_by_position/', {
      params: (start_date && end_date) ? {
        date_from: start_date,
        date_to: end_date,
      } : {}
    })
    statistics.value = (response.data || []).map(i => {

      const capitalizeLetters = (position_text) => {
        try {
          return (position_text || '-').split('_').map(i => i.charAt(0).toUpperCase() + i.slice(1, i.length)).join(' ')
        } catch {
          return position_text
        }
      }

      return {
        position_label: capitalizeLetters(i.position),
        total_revenue: parseFloat(i.total_revenue || 0),
        total_expense: parseFloat(i.total_expense || 0),
        total_profit: parseFloat(i.total_profit || 0),
      }
    })
    is_loading.value = false
  } catch {
    is_loading.value = false
    statistics.value = []
  }
}

const formatPrice = (price) => {
  if (price) {
    return (price).toLocaleString('en-UK', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2
    })
  }
  return 0
}

onMounted(async () => {
  await getStatistics()
})

watch(
    () => route.query,
    (newQuery, oldQuery) => {
      if (
          newQuery.start_date !== oldQuery.start_date ||
          newQuery.end_date !== oldQuery.end_date
      ) {
        getStatistics();
      }
    },
    {deep: true}
);

</script>

<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-0">
        <b-spinner v-if="is_loading" class="fs-10 me-2 text-secondary" style="width: 15px; height: 15px"></b-spinner>
        Financial report by Positions
      </b-card-title>
    </b-card-header>
    <b-card-body class="pt-3 mb-0">
      <div v-if="!is_loading" class="table-responsive">
        <table class="table table-sm table-nowrap mb-0">
          <thead>
          <tr class="">
            <th scope="col">Position</th>
            <th class="text-center" scope="col">Total Revenue</th>
            <th class="text-center" scope="col">Total Expense</th>
            <th class="text-center" scope="col">Total Profit</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="position in statistics" :key="`position_${position.position_label}`">
            <tr>
              <th><a class="fw-semibold">{{ (position.position_label) }}</a></th>
              <td class="text-center">$ {{ formatPrice(position.total_revenue) }}</td>
              <td class="text-center text-danger fw-medium">$ {{ formatPrice(position.total_expense) }}</td>
              <td class="text-center text-success fw-medium">$ {{ formatPrice(position.total_profit) }}</td>
            </tr>
          </template>
          <tr v-if="statistics.length === 0">
            <td colspan="4" class="py-3 text-danger">
              No report found for given period
            </td>
          </tr>
          <tr class="bg-light" v-else>
            <th>TOTAL</th>
            <th class="text-center">$ {{
                formatPrice(statistics.reduce((sum, item) => sum + item.total_revenue, 0))
              }}
            </th>
            <th class="text-center text-danger">$
              {{ formatPrice(statistics.reduce((sum, item) => sum + item.total_expense, 0)) }}
            </th>
            <th class="text-center text-success">$
              {{ formatPrice(statistics.reduce((sum, item) => sum + item.total_profit, 0)) }}
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>