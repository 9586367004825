<script>
import axios from "axios";
import {
  InquiryByStatus as InquiryByStatusModal,
} from "@/views/pages/statistics/modals";

export default {
  name: 'InquiryStatistics',
  components: {
    InquiryByStatusModal,
  },
  data() {
    return {
      inquiries: [],
      is_loading: true
    };
  },
  computed: {
    average_success_ratio() {
      try {
        return (this.total_successful_inquires_count / (this.total_inquires_count - this.total_pending_inquires)) * 100
      } catch {
        return 0
      }
    },
    total_inquires_count() {
      return this.inquiries.reduce((sum, inquiry) => sum + inquiry.total, 0)
    },
    total_successful_inquires_count() {
      return this.inquiries.reduce((sum, inquiry_item) => {
        const failStatus = inquiry_item.statuses.find(i => i.label === 'Success');
        const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
        return sum + failValue;
      }, 0)
    },
    total_pending_inquires() {
      return this.inquiries.reduce((sum, inquiry_item) => {
        const failStatus = inquiry_item.statuses.find(i => i.label === 'Pending');
        const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
        return sum + failValue;
      }, 0)
    },
  },
  methods: {
    async getInquiries() {
      this.is_loading = true;
      try {
        const {start_date, end_date} = this.$route.query;

        let response = await axios.get(`/order/inquiry_statistic/`, {
          params: (start_date && end_date) ? {
            date_from: start_date,
            date_to: end_date,
          } : {}
        });

        this.inquiries = (response.data || []).map((i) => {

          const get_success_ratio = () => {
            try {
              let total_inquiries = i.total
              let total_pending_inquiries = i.statuses.find(i => i.status === 'Pending').count || 0
              let total_success_inquiries = i.statuses.find(i => i.status === 'Success').count || 0

              return ((total_success_inquiries / ((total_inquiries - total_pending_inquiries) || 1)) * 100).toFixed(2)
            } catch {
              return 0
            }
          }

          return {
            ...i,
            overall_grade_color: this.get_overall_grade_color(i.overall_grade),
            success_ratio: get_success_ratio(),
            statuses: i.statuses.map((item) => {
              return {
                label: item.status,
                value: item.count,
                color: this.get_status_color(item.status),
                inquiries: item.inquiries || []
              }
            }),
          }
        });
        this.is_loading = false;
      } catch {
        this.inquiries = [];
        this.is_loading = false;
        alert("Could not load inquiries by managers table");
      }
    },
    get_status_color(status) {
      switch (status) {
        case 'Pending':
          return 'warning';
        case 'Quoted':
          return 'secondary';
        case 'Success':
          return 'success';
        case 'Fail':
          return 'danger';
        default:
          return 'muted';
      }
    },
    get_overall_grade_color(grade) {
      switch (grade) {
        case 'A':
          return 'success';
        case 'B':
          return 'secondary';
        case 'C':
          return 'warning';
        case 'D':
          return 'danger';
        default:
          return 'muted';
      }
    }
  },
  created() {
    this.getInquiries();
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
            newQuery.start_date !== oldQuery.start_date ||
            newQuery.end_date !== oldQuery.end_date
        ) {
          this.getInquiries();
        }
      },
      deep: true
    }
  },
};
</script>

<template>
  <InquiryByStatusModal ref="inquiryByStatusModal"/>

  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center">
      <b-card-title class="mb-0">
        <b-spinner v-if="is_loading" class="fs-10 me-2 text-secondary" style="width: 15px; height: 15px"></b-spinner>
        Inquiries by Managers
      </b-card-title>
      <img src="@/assets/images/inquiry.webp" alt="Inquiry Icon" style="height: 20px; width: 20px; margin-right: 5px">
    </b-card-header>
    <b-card-body v-if="!is_loading" class="mb-0 pb-0 pt-3">

      <div class="table-responsive">
        <table class="table table-sm table-nowrap">
          <thead>
          <tr class="">
            <th scope="col">Manager</th>
            <th class="text-center" scope="col">Total Inquiries</th>
            <th class="text-center" scope="col">Pending</th>
            <th class="text-center" scope="col">Quoted</th>
            <th class="text-center" scope="col">Success</th>
            <th class="text-center" scope="col">Fail</th>
            <th class="text-center" scope="col">Success Ratio</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in inquiries" :key="`item_${item}`">
            <tr>
              <th scope="row">
                <a class="fw-semibold" :class="{
                  'text-danger': !item.manager.username
                }">
                  {{
                    (item.manager.full_name || '').trim().length > 0 ? item.manager.full_name : item.manager.username || 'Unknown Manager'
                  }}
                </a>
              </th>
              <td @click="this.$refs.inquiryByStatusModal.openModal(item)" class="text-center">
                <h5 class="mb-0 cursor-pointer">
                  <span class="badge badge-soft-dark">
                  {{ item.total }}
                </span>
                </h5>
              </td>
              <td class="text-center" style="min-width: 50px">

                <h5 class="cursor-pointer mb-0" v-b-tooltip.hover title="Pending"
                    @click="this.$refs.inquiryByStatusModal.openModal(item, {
                      label: 'Pending',
                      color: 'warning'
                    })"
                >
                    <span class="badge badge-soft-warning">
                      {{
                        item.statuses.filter(i => i.label === 'Pending').length > 0
                            ? item.statuses.find(i => i.label === 'Pending').value
                            : 0
                      }}
                    </span>
                </h5>

              </td>
              <td class="text-center" style="min-width: 50px">
                <h5 class="cursor-pointer mb-0" v-b-tooltip.hover title="Quoted"
                    @click="this.$refs.inquiryByStatusModal.openModal(item, {
                      label: 'Quoted',
                      color: 'secondary'
                    })"
                >
                    <span class="badge badge-soft-secondary">
                      {{
                        item.statuses.filter(i => i.label === 'Quoted').length > 0
                            ? item.statuses.find(i => i.label === 'Quoted').value
                            : 0
                      }}
                    </span>
                </h5>
              </td>
              <td class="text-center" style="min-width: 50px">
                <h5 class="cursor-pointer mb-0" v-b-tooltip.hover title="Success"
                    @click="this.$refs.inquiryByStatusModal.openModal(item, {
                      label: 'Success',
                      color: 'success'
                    })"
                >
                    <span class="badge badge-soft-success">
                      {{
                        item.statuses.filter(i => i.label === 'Success').length > 0
                            ? item.statuses.find(i => i.label === 'Success').value
                            : 0
                      }}
                    </span>
                </h5>
              </td>
              <td class="text-center" style="min-width: 50px">
                <h5 class="cursor-pointer mb-0" v-b-tooltip.hover title="Fail"
                    @click="this.$refs.inquiryByStatusModal.openModal(item, {
                      label: 'Fail',
                      color: 'danger'
                    })"
                >
                    <span class="badge badge-soft-danger">
                      {{
                        item.statuses.filter(i => i.label === 'Fail').length > 0
                            ? item.statuses.find(i => i.label === 'Fail').value
                            : 0
                      }}
                    </span>
                </h5>
              </td>
              <td class="text-center">
                {{ item.success_ratio }}%
              </td>
            </tr>
          </template>
          <tr v-if="inquiries.length === 0">
            <td colspan="7" class="py-3 text-danger">
              No inquiries for given period
            </td>
          </tr>
          <tr v-else class="bg-light">
            <th>
              TOTAL
            </th>
            <th class="text-center">
              {{ inquiries.reduce((sum, inquiry_item) => sum + parseInt(inquiry_item.total || 0), 0) }}
            </th>
            <th class="text-center">
              {{
                inquiries.reduce((sum, inquiry_item) => {
                  const failStatus = inquiry_item.statuses.find(i => i.label === 'Pending');
                  const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                  return sum + failValue;
                }, 0)
              }}
            </th>
            <th class="text-center">
              {{
                inquiries.reduce((sum, inquiry_item) => {
                  const failStatus = inquiry_item.statuses.find(i => i.label === 'Quoted');
                  const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                  return sum + failValue;
                }, 0)
              }}
            </th>
            <th class="text-center">
              {{
                inquiries.reduce((sum, inquiry_item) => {
                  const failStatus = inquiry_item.statuses.find(i => i.label === 'Success');
                  const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                  return sum + failValue;
                }, 0)
              }}
            </th>
            <th class="text-center">
              {{
                inquiries.reduce((sum, inquiry_item) => {
                  const failStatus = inquiry_item.statuses.find(i => i.label === 'Fail');
                  const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                  return sum + failValue;
                }, 0)
              }}
            </th>
            <th class="text-center">
              {{ average_success_ratio.toFixed(2) }}%
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>