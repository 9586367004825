<script>

import moment from "moment/moment";

export default {
  data() {
    return {
      showModal: false,
      item: null,
      status: null,
      view_mode: 'table'
    };
  },
  computed: {
    inquiriesByStatus() {

      let get_status_color = (status) => {
        switch (status) {
          case 'pending':
            return 'warning'
          case 'quoted':
            return 'secondary'
          case 'success':
            return 'success'
          case 'fail':
            return 'danger'
          default:
            return 'muted'
        }
      }

      if (this.status) {
        try {
          return (this.item.statuses.find(i => i.label === this.status.label).inquiries || []).map((inquiry) => {
            return {
              ...inquiry,
              status_color: get_status_color(inquiry.status)
            }
          })
        } catch {
          return []
        }
      }
      return this.item.statuses.flatMap(i => i.inquiries).map((i) => {
        return {
          ...i,
          status_color: get_status_color(i.status)
        }
      })
    }
  },
  methods: {
    openModal(item, status) {
      if (item) {
        this.item = item
        this.status = status ? status : null
        this.showModal = true;
      } else {
        alert("Cannot view details")
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    formatDateTime(date) {
      if (!date) return '-'
      return moment(date).format('LLL');
    },
    formatOrderPendingSince(date) {
      if (date) {
        const now = moment();
        const orderDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
        const difference = now.diff(orderDate);

        const duration = moment.duration(difference);

        return `for ${duration.humanize()}`;
      }
      return 'Invalid date';
    },
    formatResolutionTime(timeInSeconds) {
      const duration = moment.duration(timeInSeconds, 'seconds');

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = Math.round(duration.seconds()); // Rounding seconds to nearest integer

      let formattedTime = '';

      if (hours > 0) {
        formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `;
      }
      if (minutes > 0) {
        formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''} `;
      }
      if (seconds > 0) {
        formattedTime += `${seconds} second${seconds > 1 ? 's' : ''}`;
      }

      return formattedTime.trim() || '0 seconds'; // Return '0 seconds' if all values are 0
    },
    getPreviousStatus(inquiry_status) {
      switch (inquiry_status) {
        case 'success':
          return {
            label: 'quoted',
            color: 'secondary'
          }
        case 'fail':
          return {
            label: 'quoted',
            color: 'secondary'
          }
        case 'quoted':
          return {
            label: 'pending',
            color: 'warning'
          }
      }
    }
  }
}
</script>

<template>
  <b-modal v-if="item" v-model="showModal"
           :title="item.manager.full_name"
           hide-footer hide-header centered
           size="xl"
  >
    <div class="mb-4">
      <h4 v-if="status" class="mb-1">
        <span :class="`text-${status.color}`">{{ status.label }}</span> Inquiries
      </h4>
      <h4 v-else class="mb-1">All Inquiries</h4>
      <span :class="{
        'text-muted': item.manager.username,
        'text-danger': !item.manager.username,
      }">By {{
          (item.manager.full_name || '').trim().length > 0 ? item.manager.full_name : item.manager.username || 'Unknown Manager'
        }}</span>
    </div>

    <div v-if="status" class="table-responsive">
      <table class="table table-sm table-nowrap mb-0">
        <thead>
        <tr>
          <th style="min-width: 20px;max-width: 40px" scope="col">#</th>
          <th scope="col">Date Created</th>
          <th scope="col">Text</th>
          <th scope="col">Comment</th>
          <th class="text-center" scope="col">Status</th>
          <th v-if="status.label !== 'Pending'" class="text-center" scope="col">Date <span
              class="text-secondary">Quoted</span></th>
          <th v-if="status.label === 'Success'" scope="col">Date <span
              class="text-success">Success</span></th>
          <th v-if="status.label === 'Fail'" scope="col">Date <span
              class="text-danger">Failed</span></th>
          <th v-if="status.label !== 'Pending'" class="text-end">
            Resolution Time
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(inquiry, index) in inquiriesByStatus" :key="`inquiry_by_status_${inquiry}`">
          <tr class="align-middle">
            <th style="max-width: 20px" scope="row">{{ index + 1 }}</th>
            <td style="min-width: 150px; max-width: 250px">
              {{ formatDateTime(inquiry.created) }}
            </td>
            <td style="max-width: 250px">
              <div class="text-truncate pe-4" v-html="cleanText(inquiry.text)"></div>
            </td>
            <td style="max-width: 250px">
              <div class="text-truncate pe-4" v-html="cleanText(inquiry.description)"></div>
            </td>
            <td class="text-center">
              <span class="fw-medium text-capitalize" :class="'text-' + inquiry.status_color">{{
                  inquiry.status
                }}
              </span>
              <span v-if="inquiry.status === 'pending'" class="ms-1">
                  {{ formatOrderPendingSince(inquiry.created) }}
              </span>
            </td>
            <td class="text-center" v-if="status.label !== 'Pending'">{{ formatDateTime(inquiry.quoted_at) }}</td>
            <td v-if="status.label === 'Success'">{{ formatDateTime(inquiry.success_at) }}</td>
            <td v-if="status.label === 'Fail'">{{ formatDateTime(inquiry.fail_at) }}</td>
            <td class="text-end fs-12" v-if="inquiry.status !== 'pending'">
              <div class="fs-12">
                From
                <b class="text-capitalize" :class="'text-' + getPreviousStatus(inquiry.status).color">
                  {{ getPreviousStatus(inquiry.status).label }}
                </b>
                to
                <b class="text-capitalize" :class="'text-'+ inquiry.status_color">{{ inquiry.status }}</b>
              </div>
              In {{ formatResolutionTime(inquiry.resolution_time) }}
            </td>
          </tr>
        </template>
        <tr v-if="inquiriesByStatus.length === 0">
          <td colspan="7">
            <h6 v-if="status" class="mb-0">
              {{ item.manager.full_name }} has no <span :class="`text-${status.color}`">{{ status.label }}</span>
              inquiries!
            </h6>
            <h6 v-else class="mb-0">
              {{ item.manager.full_name }} has no inquiries yet!
            </h6>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="table-responsive">
      <table class="table table-sm table-nowrap mb-0">
        <thead>
        <tr>
          <th style="min-width: 20px;max-width: 30px" scope="col">#</th>
          <th scope="col">Date Created</th>
          <th scope="col">Text</th>
          <th scope="col">Comment</th>
          <th class="text-center" scope="col">Status</th>
          <th scope="col" class="text-center">Date <span class="text-secondary">Quoted</span></th>
          <th scope="col" class="text-center">Date <span class="text-success">Success</span></th>
          <th scope="col" class="text-center">Date <span class="text-danger">Failed</span></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(inquiry, index) in inquiriesByStatus" :key="`inquiry_by_status_${inquiry}`">
          <tr>
            <th style="max-width: 40px" scope="row">{{ index + 1 }}</th>
            <td style="min-width: 150px; max-width: 250px">
              {{ formatDateTime(inquiry.created) }}
            </td>
            <td style="max-width: 250px">
              <div v-b-tooltip.hover :title="cleanText(inquiry.text)" class="text-truncate"
                   v-html="cleanText(inquiry.text)"></div>
            </td>
            <td style="max-width: 250px">
              <div v-b-tooltip.hover :title="cleanText(inquiry.description)" class="text-truncate"
                   v-html="cleanText(inquiry.description)"></div>
            </td>
            <td class="text-center">
              <b class="fw-medium text-capitalize" :class="'text-' + inquiry.status_color">
                {{ inquiry.status }}
              </b>
              <span v-if="inquiry.status === 'pending'" class="ms-1">
                {{ formatOrderPendingSince(inquiry.created) }}
              </span>
            </td>
            <td class="text-center">{{ formatDateTime(inquiry.quoted_at) }}</td>
            <td class="text-center">{{ formatDateTime(inquiry.success_at) }}</td>
            <td class="text-center">{{ formatDateTime(inquiry.fail_at) }}</td>
          </tr>
        </template>
        <tr v-if="inquiriesByStatus.length === 0">
          <td colspan="7">
            <h6 v-if="status" class="mb-0">
              {{ item.manager.full_name }} has no <span :class="`text-${status.color}`">{{ status.label }}</span>
              inquiries!
            </h6>
            <h6 v-else class="mb-0">
              {{ item.manager.full_name }} has no inquiries yet!
            </h6>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>

<style scoped>
.text-truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>